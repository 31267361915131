import axios from "../axios"
import { toast } from "react-toastify"

export const reportWaf = async (
  siteId,
  wafHost,
  requestId,
  requestEmail,
  requestContent,
  base
) => {
  try {
    const query = `
      mutation ($input: WafReportInput!){
        guest {
          waf {
            report(input: $input) {
              code
              message
            }
          }
        }
      }`

    const queryData = {
      query,
      variables: {
        input: {
          siteId,
          wafHost,
          requestId,
          requestEmail,
          requestContent,
        },
      },
    }
    let url = "https://api.wtotem.com"
    if (base === "0") {
      url = "https://api-1.2ke2xgwx4.wtotem.net"
    } else if (base === "2") {
      url = "https://api.webtotem.kz"
    }
    const response = await axios
      .post(url + "/graphql", queryData)
      .catch(err => {
        if (err.response.status === 404) {
          throw new Error(`${err.config.url} not found`)
        }
        if (err.response.status === 400) {
          err.response.data.errors.map(item => {
            toast(item.message)
          })
        }
        throw err
      })
    if (response.status === 200 && !response.data.errors) {
      return response.data
    } else {
      response.data.errors.map(item => {
        toast(item.message)
      })
      return false
    }
  } catch (e) {
    return false
  }
}

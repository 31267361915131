import React, { useState } from "react"
import "./styles.css"
import Input from "../input"
import { useForm } from "react-hook-form"
import { useTranslation } from "../../utils"
import { reportWaf } from "../../api/report"
import Spinner from "../../images/spinner.svg"
import { toast } from "react-toastify"
import { navigate } from "../../../.cache/gatsby-browser-entry"

const ReportForm = ({ siteId, wafHost, requestId, base }) => {
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)

  const onSubmit = async values => {
    setLoading(true)
    const res = await reportWaf(
      siteId,
      wafHost,
      requestId,
      values.email,
      values.message,
      base
    )
    setLoading(false)
    if (res.data.guest.waf.report.code === 200) {
      navigate("/")
      toast("Your request has been successfully completed")
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="report-page__form">
      {loading && (
        <div className="wt-loader-wrapper">
          <img src={Spinner} alt="spinner" />
        </div>
      )}
      <Input
        label={t("Your Email")}
        name="email"
        className="wt-margin-bottom-15"
        ref={register({
          required: "Required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address",
          },
        })}
      />
      <Input
        label={t("Message")}
        name="message"
        textarea
        className="wt-margin-bottom-15"
        ref={register({ required: true })}
      />

      <input
        type="submit"
        className="wt-button wt-button--primary"
        value="Send Message"
      />
    </form>
  )
}

export default ReportForm

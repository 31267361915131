import React, { useEffect } from "react"
import "./styles.css"
import Icon from "../../../images/icon.png"
import ReportForm from "../../../components/report-form"
import { getQueryParams } from "../../../utils"
import { navigate } from "gatsby"

const ReportPage = ({ location }) => {
  const siteId = getQueryParams(location.search)["siteId"]
  const wafHost = getQueryParams(location.search)["wafHost"]
  const requestId = getQueryParams(location.search)["requestId"]
  const base = getQueryParams(location.search)["base"]

  useEffect(() => {
    if (!(siteId && wafHost && requestId && base)) {
      navigate("/")
    }
  }, [])

  return (
    <div className="report-page">
      <div className="report-page__title">You have been blocked</div>
      <div className="report-page__hint">
        <p>
          For immediate unlock your website
          <br />
          please write us your problem and
          <br />
          Error code
        </p>
      </div>

      <ReportForm
        siteId={siteId}
        wafHost={wafHost}
        requestId={requestId}
        base={base}
      />

      <div>
        <img src={Icon} alt="icon" className="report-page__logo" />
      </div>
    </div>
  )
}

export default ReportPage
